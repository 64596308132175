<style lang="scss">
.digitales-prospekt {
  .tabelle-box {
    strong {
      padding-left: 0 !important;
    }
    .col-lg-4 {
      @media (max-width: 992px) {
        text-align: right !important;
      }
    }
    .small-text-align-center {
      @media (max-width: 992px) {
        text-align: center !important;
      }
    }
  }
  .tab-group-container {
    margin: 50px 0;
    .title-container {
      .title {
        &:hover {
          background: #eee;
        }
      }
      .active {
      }
    }
  }
  .img-smartphone {
    max-height: 600px;
  }
  .img-link {
    display: block;
  }
}
</style>

<template>
  <div class="digitales-prospekt content container">
    <h1>Digitales Prospekt</h1>
    <p class="intro-txt"><strong>Das Schaufenster im Netz.</strong> Ohne großen Zusatzaufwand und maximal effizient erreichen Sie damit 1,942 Millionen User bzw. zusätzlich noch 40.095 E-Paper-Abonnenten!</p>
    <div class="intro-img">
      <img src="@/assets/img/img-content/intro-img/digitales-prospekt.jpg" alt="Digitales Prospekt" class="img-fluid" />
    </div>

    <h2>Ihre Werbemöglichkeiten</h2>

    <TabGroup>
      <Tab tabid="digitalespros-tab1" :title="'kleinezeitung.at'" active>
        <div class="row inner-content">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <a href="https://digprospekt.smd-digital.at/examples/digi-prospekt-slider/index.html" target="_bank" class="img-link">
                <img src="@/assets/img/img-content/beilagensonderwerbeformen/digitalesprospekt-smartphone.png" alt="Smartphone" class="img-fluid img-smartphone" />
              </a>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <ul class="content-ul">
              <li>Diese Werbeform ist optimiert für die Anwendung mobiler Endgeräte.</li>
              <li>Der Aufwand ist minimal &ndash; sowohl in der Erstellung als auch in der Pflege.</li>
              <li>Schaffen Sie eigene Themenwelten und spielen Sie die Inhalte zielgruppengenau aus.</li>
            </ul>
            <div class="tabelle-box">
              <div class="headline">
                Digitales Prospekt
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-4 text-align-left"></div>
                <div class="col-lg-4 text-align-right">Sichtkontakt</div>
                <div class="col-lg-4 text-align-right">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 text-align-left small-text-align-center"><strong>Paket Silber</strong></div>
                <div class="col-6 text-align-left visible-small">Sichtkontakt</div>
                <div class="col-lg-4 col-6 text-align-right">10.000</div>
                <div class="col-6 text-align-left visible-small">Tarif</div>
                <div class="col-lg-4 col-6 text-align-right">500,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 text-align-left small-text-align-center"><strong>Paket Gold</strong></div>
                <div class="col-6 text-align-left visible-small">Sichtkontakt</div>
                <div class="col-lg-4 col-6 text-align-right">30.000</div>
                <div class="col-6 text-align-left visible-small">Tarif</div>
                <div class="col-lg-4 col-6 text-align-right">1.500,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 text-align-left small-text-align-center"><strong>Paket Platin</strong></div>
                <div class="col-6 text-align-left visible-small">Sichtkontakt</div>
                <div class="col-lg-4 col-6 text-align-right">100.000</div>
                <div class="col-6 text-align-left visible-small">Tarif</div>
                <div class="col-lg-4 col-6 text-align-right">5.000,00</div>
              </div>
            </div>
            <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
          </div>
        </div>
      </Tab>
      <Tab tabid="digitalespros-tab2" :title="'E-Paper'">
        <div class="row inner-content">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/beilagensonderwerbeformen/digitales-prospekt-e-paper-v2.png" alt="E-Paper" class="img-fluid" />
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <ul class="content-ul">
              <li><strong>Sie legen Ihr Prospekt der Kleinen Zeitung bei und wollen es zusätzlich auch unseren E-Paper-Abonnenten präsentieren?</strong> Bilden Sie Ihr Prospekt oder Ihre Sonderwerbeform zusätzlich zur Beilage in der Print-Zeitung am jeweiligen Erscheinungstag auch im E-Paper ab – hohe Aufmerksamkeit durch Platzierung auf der Startseite.</li>
              <li>Alle digitalen Prospektbeilagen oder Sonderwerbeformen können in das E-Paper übernommen werden. Zusätzlich besteht die Möglichkeit, die Abbildung nur in der Steiermark oder nur in Kärnten zu buchen.</li>
            </ul>

            <div class="tabelle-box ">
              <div class="headline">
                E-Paper-Abbildung
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-6 text-align-left"></div>
                <div class="col-lg-6 text-align-right">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-6 text-align-left">E-Paper-Angebot</div>
                <div class="col-lg-6 col-6 text-align-right">199,00</div>
              </div>
            </div>
            <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
          </div>
        </div>
      </Tab>
    </TabGroup>

    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/beilagen/Digitales-Prospekt-07-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif - Digitales Prospekt
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>722 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Digitales Prospekt',
  components: {
    //Modal,
    TabGroup,
    Tab,
  },
});
</script>
